import { clientStore as store } from "../store";
import { headerType,  cancelPendingReq as cancelPendingReqStoreKeys, } from "../store/keys";
import Vue from "vue";
import { common } from "../mixins/common";

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {
  //console.log(to.name, headerType)
  if (to.name != "home") {
    store.dispatch(
      `${headerType.namespace}/${headerType.actions.headerToggle}`,
      true
    );
  } else {
    store.dispatch(
      `${headerType.namespace}/${headerType.actions.headerToggle}`,
      false
    );
  }

  return next();
}
export function cancelPendingRequests(to, from, next) {
  store.dispatch(
    `${cancelPendingReqStoreKeys.namespace}/${cancelPendingReqStoreKeys.actions.cancel_Req}`
  );
  next();
}

export function decryptRouteParams(to, from, next) {
  if (to.meta.paramBinding) {
    try {
      Vue.prototype.$decryptedRouteParams = common.methods.PropertyBinding(
        to.meta.paramBinding,
        to.params
      );
    } catch (ex) {
      console.log(ex)
      return next({
        name: "category",
      });
    }
  }

  return next();
}

export function checkQueryParams(to, from, next) {
  if (!common.methods.hasQueryParams(to) && common.methods.hasQueryParams(from)) {
    return next({ name: to.name, params: to.params, query: from.query });
  } else {
    return next();
  }
}

export const appConstants = Object.freeze({
    enums: {
        programCategory: {
            Class: '1',
            Exam: '2'
        },
        PaymentOption: {
            PaymentPlan: '1',
            Trial: '2',
            Free: '3'
        },
        pagingMode: {
            loadMore: 1,
            replace: 2,
        },
        subscriptionType: {
            Trial: 1,
            Free: 2,
            All: -1
        }
    }
});
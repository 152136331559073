<template>
  <div v-if="popularExams && popularExams.length > 0">
    <!-- {{ popularExams }} -->
    <ssr-carousel loop :slides-per-page='3' :responsive='[{maxWidth: 1024,slidesPerPage: 2}, {maxWidth: 600,slidesPerPage: 1}]' show-dots >
      <div
        class="slide"
        v-for="(courseListItem) in popularExams"
        :key="courseListItem.SOSId"
      >
        <course-card :model="courseListItem"></course-card>
      </div>
    </ssr-carousel>

  </div>
</template>

<script>

import SsrCarousel from "vue-ssr-carousel";
import ssrCarouselCss from "vue-ssr-carousel/index.css";
import courseCard from "../components/courseCard.vue";
import { mapGetters, mapActions } from "vuex";
import {
  exam as examStoreKeys,
  course as courseStoreKeys,
  classCourses as classCoursesStoreKeys,
  popularExams as popularExamsStoreKeys,
} from "../store/keys";
import { appConstants } from "../common/constants";
// import cartConfirmationModal from "../cartConfirmationModal.vue";
// import { mapGetters, mapActions } from "vuex";
// import { appConstants } from "../common/constants";
// import featuredLoader from "../components/featuredLoader.vue";
// import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, featuredCourses as featuredCoursesStoreKeys, cart as cartStoreKeys } from "../store/keys";
export default {
  components: {
    SsrCarousel,
    courseCard,
    // featuredLoader
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.hash) {
      var elm = this.$route.hash.slice(1);
      this.scrollToElement(elm);
    }
    this.getPopularExams();
    // let routeParams = this.$route.params;
    // this.getExamsGroups("medical");
    // window.addEventListener("scroll", () => {
    //   this.scrollY = Math.round(window.scrollY);
    //   if (this.$refs.header) {
    //     this.headerTop = document.getElementById("header").offsetTop;
    //     if (this.scrollY > this.headerTop) {
    //       this.isHeaderSticky = true;
    //       document.body.classList.add("exam-body");
    //     } else {
    //       this.isHeaderSticky = false;
    //       document.body.classList.remove("exam-body");
    //     }
    //   }
    // });
  },
  async serverPrefetch() {
    return this.getPopularExams();
  },
  data() {
    return {
      selectedCourseGroupCategory: {
        Id: 0,
        Name: "",
      },
      coursePaging: {
        pageNumber: 1,
        pageSize: 9,
        showRecordsNotFound: false,
      },
      settings: {
        centerMode: false,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              arrows: false,
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              dots: true,
              centerMode: false,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              dots: true,
              arrows: false,
              centerMode: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(examStoreKeys.namespace, examStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(
      classCoursesStoreKeys.namespace,
      classCoursesStoreKeys.getters
    ),
    ...mapGetters(
      popularExamsStoreKeys.namespace,
      popularExamsStoreKeys.getters
    ),
  },
  methods: {
    ...mapActions(examStoreKeys.namespace, examStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(
      classCoursesStoreKeys.namespace,
      classCoursesStoreKeys.actions
    ),
    ...mapActions(
      popularExamsStoreKeys.namespace,
      popularExamsStoreKeys.actions
    ),

    getExamsGroups(item) {
      return this.get_Exams_Groups({
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
      })
        .then((response) => {
          if (this.sosCategories.length > 0) {
            var category = this.sosCategories.find(
              (c) => c.CourseGroupName.toLowerCase() == item
            );
            this.updateSelectedsosCategory(category || this.sosCategories[0]);
          }
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    loadMore() {
      this.coursePaging.pageNumber++;
      this.getClassCourses(appConstants.enums.pagingMode.loadMore);
    },
    updateSelectedsosCategory(sosCategory) {
      this.selectedCourseGroupCategory.Id = sosCategory.CourseGroupId;
      this.selectedCourseGroupCategory.Name = sosCategory.CourseGroupName;
      this.coursePaging.pageNumber = 1;
      //this.resetClassCourses();
      this.getClassCourses();
    },
    // resetClassCourses() {
    //   this.reset_ClassCourses().then((response) => {
    //     this.coursePaging.pageNumber = 1;
    //     this.getClassCourses();
    //   });
    // },
    getPopularExams(pagingMode) {
      return this.get_PopularExams({
        data: {
          CourseListFilter: 1,
          // CourseGroupId: this.selectedCourseGroupCategory.Id,
          ProgramCategoryId: appConstants.enums.programCategory.Exam,
          PageNumber: this.coursePaging.pageNumber,
          PageSize: this.coursePaging.pageSize,
        },
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
        pagingMode: pagingMode,
      })
        .then((response) => {
          if (this.popularExams.length < 3) {
            this.settings.centerMode = true;
          }
          this.showRecordsNotFound = true;
          // this.coursePaging.pageNumber = response.PageNumber;
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
  },
};
</script>

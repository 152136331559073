<template>
  <div v-if="model">
    <div @click="gotoDetail(model)" class="exams-box">
      <div class="img-container">
        <img :src="model.CourseImage" :alt="model.DisplayName" width="348" height="163" />
        <span v-show="model.EnableTrial" class="trial-badge">Free Trial</span>
        <span v-show="model.IsFree" class="trial-badge">Free</span>
      </div>
      <div class="container">
        <div class="course-card-title">
          <h4 class="course-title" v-show="model.DisplayName">
            <small class="d-block course-group" v-show="model.CourseGroupName">{{ model.CourseGroupName }}</small>
            <div v-b-tooltip.hover :title="model.DisplayName" class="course-card-display-name">{{ model.DisplayName }}
            </div>
            <span v-show="model.DurationLabel" class="d-block month-badge">
              Course Duration: <span> {{ model.DurationLabel }}</span></span>
          </h4>
        </div>
        <div class="wrapper">
          <div class="details-cntr">
            <div class="course-card-details-container">
              <!-- (model.Summary.VideoCount > 0 || model.Summary.TestCount > 0 || model.Summary.ReadingCount > 0 || model.Summary.AssignmentCount > 0) -->
              <div class="row gap-4" v-show="model.Summary && !model.IsSpecialActivity">
                <div class="details-width" :class="model.Summary.VideoCount == 0 ? 'noDataAvail' : ''">
                  <!-- v-if="model.Summary.VideoCount > 0" -->
                  <div class="course-card-details">
                    <span class="details-count">
                      <i class="fal fa-play-circle"></i>
                      {{ model.Summary.VideoCount != 0 ? model.Summary.VideoCount : "" }} Videos
                    </span>
                  </div>
                </div>
                <div class="details-width" :class="model.Summary.ReadingCount == 0 ? 'noDataAvail' : ''">
                  <div class="course-card-details">
                    <span class="details-count">
                      <i class="fal fa-book-open"></i>
                      {{ model.Summary.ReadingCount != 0 ? model.Summary.ReadingCount : "" }} Readings
                    </span>
                  </div>
                </div>
                <div class="details-width" :class="model.Summary.TestCount == 0 ? 'noDataAvail' : ''">
                  <div class="course-card-details">
                    <span class="details-count">
                      <i class="fal fa-file-signature"></i>
                      {{ model.Summary.TestCount != 0 ? model.Summary.TestCount : "" }} Quizzes
                    </span>
                  </div>
                </div>
                <div class="details-width" :class="model.Summary.AssignmentCount == 0 ? 'noDataAvail' : ''">
                  <div class="course-card-details">
                    <span class="details-count">
                      <i class="fal fa-copy"></i>
                      {{ model.Summary.AssignmentCount != 0 ? model.Summary.AssignmentCount : "" }} Assignments
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="model.IsSpecialActivity">{{ model.ShortDescription }}</div>
            </div>

            <div class="course-card-details-container payment-plans safari-only">
              <div v-if="model.PaymentPlans.length" class=" gap-5 d-flex justify-content-between align-items-center">
                <div class="">
                  Starting from
                </div>
                <div class="text-right d-flex justify-content-between align-items-center">
                  <del class="course-card-strike"><strong class="course-card-strike pr-2"
                      v-if="getMinValue.PriceStrikethrough"> {{
    getMinValue.PriceStrikethroughDisplay.slice(0, -3) }}
                    </strong></del>
                  <strong v-if="getMinValue.PriceDisplay"> {{ getMinValue.PriceDisplay.slice(0, -3) }} /
                  </strong>
                  <div v-b-tooltip.hover :title="getMinValue.Title"
                    class="course-price-display  d-flex justify-content-between align-items-center">

                    <strong class=".d-none .d-md-block .d-lg-none safar-only">{{
    getMinValue.Title.length > 14 ? getMinValue.Title.slice(0, 14) + '...' : getMinValue.Title
                      }}</strong>
                  </div>
                </div>
              </div>
              <div v-else-if="!model.IsSpecialActivity"
                class=" gap-4  d-flex justify-content-center align-items-center">
                <b>Coming Soon</b>
              </div>
            </div>
          </div>
          <div class="course-btn-cntr-1">
            <span class="course-detail-btn">Course Details</span>
          </div>
          <div class="course-btn-cntr-2">
            <button class="btn secondary-btn">Course Details</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CartModel } from "../models/app/cartModel";
import { appConstants } from "../common/constants";
import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, classCourses as classCoursesStoreKeys, checkout as checkoutStoreKeys, cart as cartStoreKeys } from "../store/keys";
import cryptojs from "../plugins/crypto";
export default {
  props: {
    model: null,
    programCategoryId: null,
  },
  data() {
    return {
      isTrial: false,
      selectedPaymentOption: appConstants.enums.PaymentOption.PaymentPlan,
      // selectedPaymentPlan: {
      //   SOSPaymentPlanId: 0,
      // },
    };
  },
  mounted() {
    // console.log("mounted" + this.model.SOSName);
    // if (this.model.PaymentPlans) {
    //   this.setSelectedPaymentPlan(this.model.PaymentPlans[0]);
    // }
  },
  updated() {
    // console.log("updated" + this.model.SOSName);
    // if (this.model.PaymentPlans ) {//&& this.selectedPaymentPlan.SOSPaymentPlanId === 0
    //   this.setSelectedPaymentPlan(this.model.PaymentPlans[0]);
    // }
  },
  computed: {
    ...mapGetters(courseDetailStoreKeys.namespace, courseDetailStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
    ...mapGetters(classCoursesStoreKeys.namespace, classCoursesStoreKeys.getters),
    getMinValue() {
      if (this.model && this.model.PaymentPlans.length) {
        return this.model.PaymentPlans.reduce((previous, current) => {
          return current.Price < previous.Price ? current : previous;
        });
      }
    },

  },

  methods: {
    ...mapActions(courseDetailStoreKeys.namespace, courseDetailStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    ...mapActions(checkoutStoreKeys.namespace, checkoutStoreKeys.actions),
    ...mapActions(classCoursesStoreKeys.namespace, classCoursesStoreKeys.actions),

    addToCart(model) {
      let selectedCoursePlan = this.selectedCoursePlan(model.SOSId, model.CourseGroupId);

      let cartModel = new CartModel();
      cartModel.ProgramCategoryId = this.selectedProgramCategory.id;
      cartModel.CourseGroupCategoryId = this.selectedSOSCategory.id;
      cartModel.ProgramId = this.selectedProgram.id;
      cartModel.BoardId = this.selectedBoard.id;

      cartModel.InstituteId = model.InstituteId;
      cartModel.CourseGroupId = model.CourseGroupId;
      cartModel.SOSId = model.SOSId;
      cartModel.CourseName = model.SOSName;

      cartModel.PaymentOption = this.selectedPaymentOption;
      cartModel.SOSPaymentPlanId = selectedCoursePlan.SOSPaymentPlanId;

      if (this.selectedPaymentOption === appConstants.enums.PaymentOption.PaymentPlan) {
        cartModel.Amount = selectedCoursePlan.Price;
        cartModel.AmountDisplay = selectedCoursePlan.PriceDisplay;

        cartModel.AmountStrikethroughDisplay = selectedCoursePlan.PriceStrikethroughDisplay;
      } else if (this.selectedPaymentOption === appConstants.enums.PaymentOption.Trial) {
        cartModel.Amount = 0.0;
        cartModel.AmountDisplay = this.toCurrency(0); //"Rs 0.00";
        cartModel.AmountStrikethroughDisplay = this.toCurrency(0); //"Rs 0.00";
      }

      this.add_ToCart({
        data: cartModel,
        config: {
          loader: {
            show: true,
          },
        },
      })
        .then((response) => {
          if (!this.isTrial) {
            this.$bvModal.show("confirmCartModal");
          }
          if (this.selectedPaymentOption === appConstants.enums.PaymentOption.Trial) {
            this.checkoutTrial();
          }
          //this.reset_SelectedCourseInfo();
        })
        .catch((ex) => {
          this.$alertError(ex);
        });
    },
    // checkoutTrial() {
    //   this.checkout_cart().then((response) => {
    //     // this.$router.push({ name: "signUp" });
    //     window.location.href = `${process.env.VUE_APP_PORTAL_URL}/invoice/checkOut`;
    //   });
    // },
    checkoutTrial() {
      this.check_Out();
    },
    AddToTrial(model, isTrial) {
      this.isTrial = isTrial;
      this.selectedPaymentOption = appConstants.enums.PaymentOption.Trial;
      this.addToCart(model);
    },
    goToCart() {
      this.$router.push({ name: "cart" }).catch(() => { });
    },
    setSelectedPaymentPlan(plan) {
      // if (
      //   this.selectedPaymentOption ===
      //   appConstants.enums.PaymentOption.PaymentPlan
      // ) {

      this.update_SelectedCoursePlan({
        data: {
          selectedCourse: this.model,
          selectedSOSPaymentPlanId: plan.SOSPaymentPlanId,
          ProgramCategoryId: appConstants.enums.programCategory.Exam,
        },
      }).catch((ex) => {
        this.$alertError(ex.message);
      });
      //}
    },
    gotoDetail(model) {
      if (this.programCategoryId == appConstants.enums.programCategory.Class) {
        this.$router.push({
          name: "classDetail",
          params: {
            course: this.RemoveSpecialChars(model.SOSName),
            board: this.RemoveSpecialChars(this.selectedCourseInfo.board.name),
            group: this.RemoveSpecialChars(this.selectedCourseInfo.courseGroup.name),
            sosId: cryptojs.UTF.encrypt(model.SOSId),
            boardId: cryptojs.UTF.encrypt(this.selectedCourseInfo.board.id),
            courseGroupId: cryptojs.UTF.encrypt(this.selectedCourseInfo.courseGroup.id || model.CourseGroupId),
          },
        }).catch(() => { });
        // this.$router.push("/class/detail/" + this.RemoveSpecialChars(model.SOSName) + "/" + this.RemoveSpecialChars(this.selectedCourseInfo.board.name) + "/" + this.RemoveSpecialChars(this.selectedCourseInfo.courseGroup.name) + "?s=" + model.SOSId + "&b=" + this.selectedCourseInfo.board.id + "&g=" + this.selectedCourseInfo.courseGroup.id);
      } else {
        this.$router.push({
          name: "examDetail",
          params: {
            course: this.RemoveSpecialChars(model.SOSName),
            sosId: cryptojs.UTF.encrypt(model.SOSId),
            courseGroupId: cryptojs.UTF.encrypt(this.selectedCourseInfo.sosCategory.id || model.CourseGroupId),
          },
        }).catch(() => { });
        // this.RouteTo("/exam/detail/" + this.RemoveSpecialChars(model.SOSName) + "?s=" + model.SOSId + "&g=" + model.CourseGroupId);
      }
    },
  },
};
</script>

import LmsBaseService from "../lmsBase.service";
import { apiEndPoints } from "../constants";

export default class CourseService extends LmsBaseService {
  constructor() {
    super({});
  }
  getClasses(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getClasses, false, param.data);
  }
  getClassBoards(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getClassBoards, false, param.data);
  }
  getClassGroups(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getClassGroups, false, param.data);
  }
  getClassCourses(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getClassCourses, false, param.data);
  }
  getSpecialCourses(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getClassCourses, false, param.data);
  }
  getPopularExams(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getClassCourses, false, param.data);
  }
  getCourseDetail(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getCourseDetail, false, param.data);
  }
  getExams(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getExams, false, param.data);
  }
  getExamsGroups(param) {
    this.configuration.callbacks.params = param.config;
    return this.get(apiEndPoints.course.getExamsGroups, false, param.data);
  }
  // getExamCourses(param) {
  //     this.configuration.callbacks.params = param.config;
  //     return this.get(apiEndPoints.course.getExamCourses , false, param.data);
  // }
  // getSpecialCoursesCount(param) {
  //     this.configuration.callbacks.params = param.config;
  //     return this.get(apiEndPoints.course.getSpecialCoursesCount , false, param.data);
  // }
}
